import React, { useState } from "react"
import { StaticQuery, graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import styled from "styled-components"

const ExpandButton = styled.button`
  display: block;
  margin: auto;
  cursor: pointer;
  text-align: center;
  color: #000;
  background: none;
  border: none;
  font-size: 1.4rem;
  font-weight: bold;
`;

const Container = styled.div`
  margin-bottom: 5rem;

  .news {
    list-style: none;
    padding: 0px;

    &__item {
      margin-bottom: 1.5rem;

      &__header {
        cursor: pointer;
        display: flex;
        flex-direction: column;

        > div {
          background-color: rgb(243, 243, 243);
          width: 100%;
        }
      }

      &__title {
        font-size: 1.4rem;
        margin: 1rem 1.5rem 0px;
      }

      &__date {
        background-color: rgb(255, 187, 60);
        padding: 1rem;
        display: flex;
        width: 100px;
        justify-content: center;
        align-items: center;
        font-weight: 700;
      }

      &__summary {
        margin: 0.5rem 1.5rem 1rem;
        font-size: 1.2rem;
      }

      &__body {
        background-color: rgb(243, 243, 243);
        border-top: 1px solid rgb(255, 187, 60);
        padding: 1rem 1.5rem;
        font-size: 1.4rem;

        h4,
        h5 {
          margin: 0;
        }

        h5 + .note {
          margin-top: 0;
        }

        a {
          color: #ff691f;
        }

        ul {
          list-style-type: disc;
          padding-left: 2rem;

          li {
            margin-bottom: 1rem;
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }

      .note {
        font-size: 1.2rem;
      }
    }

    @media (min-width: 768px) {
      &__item {
        &__header {
          flex-direction: row;
        }

        &__title {
          font-size: 1.6rem;
          margin: 0.5rem 1.5rem;
        }

        &__body {
          font-size: 1.6rem;
          padding: 2rem 3rem;
        }
      }
    }
  }
`

const AccordionItem = ({ data, index }) => {
  const [isActive, setIsActive] = useState(false)
  return (
    <li className="news__item">
      <div
        className="news__item__header"
        onClick={() => setIsActive(!isActive)}
        onKeyUp={() => setIsActive(!isActive)}
        role="button"
        tabIndex={index}
      >
        <time className="news__item__date">
          {data.datetime.split("T")[0].split("-").join(".")}
        </time>
        <div>
          <h3 className="news__item__title">{data.title}</h3>
          <p className="news__item__summary">{data.summary}</p>
        </div>
      </div>
      {isActive && (
        <div className="news__item__body">{renderRichText(data.content)}</div>
      )}
    </li>
  )
}

export default function News(props) {
  const [expanded, setExpanded] = useState(false)

  return (
    <StaticQuery
      query={graphql`
        query News {
          allContentfulNews(sort: { fields: createdAt, order: DESC }) {
            edges {
              node {
                id
                title
                content {
                  raw
                }
                datetime
                summary
              }
            }
          }
        }
      `}
      render={data => (
        <Container>
          <ul className="news">
            {data.allContentfulNews.edges.map(({ node }, i) => {
              return (
                (expanded || i < 6) && (
                  <AccordionItem
                    data={node}
                    {...props}
                    key={node.id}
                    index={i}
                  />
                )
              )
            })}
          </ul>
          {!expanded && (
            <ExpandButton type="button" onClick={() => setExpanded(!expanded)}>
              もっとみる
            </ExpandButton>
          )}
        </Container>
      )}
    />
  )
}
